import * as validators from '../validators';
import converters from '../converters';

export default () => {
    return {
        key: {
            validators: [validators.string]
        },
        countryCode: {
            validators: [validators.countryCode],
            required: true
        },
        limit: {
            validators: [validators.limit]
        },
        view: {
            validators: [validators.oneOfValue(
                ['AR', 'IL', 'MA', 'IN', 'PK', 'Unified', 'RU', 'TR', 'CN'],
                'view')]
        },
        offset: {
            validator: [validators.naturalInteger],
            name: 'ofs'
        },
        language: {
            validators: [validators.languageCode]
        },

        /**
         * Represents the street's number for the structured address.
         *
         * @attribute streetNumber
         * @param {String} [options.streetNumber] The street's number.
         */
        streetNumber: {
            validators: [validators.string]
        },

        /**
         * Represents the street's name for the structured address.
         *
         * @attribute streetName
         * @param {String} [options.streetName] The street's name.
         */
        streetName: {
            validators: [validators.string]
        },

        /**
         * Represents the cross street's name for the structured address.
         *
         * @attribute crossStreet
         * @param {String} [options.crossStreet] The cross street's name.
         */
        crossStreet: {
            validators: [validators.string]
        },

        /**
         * Represents the municipality (city/town) for the structured address.
         *
         * @attribute municipality
         * @param {String} [options.municipality] The municipality's name (city/town).
         */
        municipality: {
            validators: [validators.string]
        },

        /**
         * Represents the municipality subdivision (sub/super city) for the structured address.
         *
         * @attribute municipalitySubdivision
         * @param {String} [options.municipalitySubdivision] The municipality subdivision (sub/super city).
         */
        municipalitySubdivision: {
            validators: [validators.string]
        },

        /**
         * Represents the area's name for the structured address.
         *
         * @attribute countryTertiarySubdivision
         * @param {String} [options.countryTertiarySubdivision] The name of the area.
         */
        countryTertiarySubdivision: {
            validators: [validators.string]
        },

        /**
         * Represents the county's name for the structured address.
         *
         * @attribute countrySecondarySubdivision
         * @param {String} [options.countrySecondarySubdivision] The county's name.
         */
        countrySecondarySubdivision: {
            validators: [validators.string]
        },

        /**
         * Represents the state or province's name for the structured address.
         *
         * @attribute countrySubdivision
         * @param {String} [options.countrySubdivision] The state or province.
         */
        countrySubdivision: {
            validators: [validators.string]
        },

        /**
         * Represents the zip code or postal code for the structured address.
         *
         * @attribute postalCode
         * @param {String} [options.postalCode] The zip or postal code.
         */
        postalCode: {
            validators: [validators.string]
        },

        protocol: {
            validators: [validators.oneOfValue(['http', 'https'], 'protocol')]
        },
        bestResult: {
            validators: [validators.bool]
        },
        timeZone: {
            validators: [validators.string]
        },
        extendedPostalCodesFor: {
            validators: [validators.string]
        },
        entityTypeSet: {
            converters: [converters.array, converters.commaSeparated],
            validators: [validators.entityType]
        }
    };
};
